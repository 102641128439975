/* eslint-disable import/no-dynamic-require */
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { Sidebar, InterestCard } from '@/components';
import isServer from '@/utils/isServer';

if (!isServer) {
  const { required } = require('vee-validate/dist/rules');
  const { extend } = require('vee-validate');

  extend('required', {
    ...required,
    message: 'Dit veld is vereist.',
  });
}

export default {
  metaInfo: {
    title: 'Activity page',
  },
  name: 'Activity',
  components: {
    [Sidebar.name]: Sidebar,
    [InterestCard.name]: InterestCard,
    // ValidationObserver,
    // ValidationProvider,
    // VueEditor,
    DatePicker,
  },
  props: {},
  data: () => ({
    editMode: false,
    activity: {
      tookPlaceOn: '',
      location: '',
      disciplineId: '',
      distanceInKm: 0,
      durationInMs: '',
    },
    hours: 0,
    minutes: 0,
    seconds: 0,
    disciplineErrorMessage: '',
    datepicked: new Date(),
    timepicked: `${new Date().toISOString()}`,
    disciplineName: '',
    isLoaded: false,
  }),
  computed: {
    ...mapGetters('discipline', {
      disciplineList: 'getDisciplineList',
    }),
    ...mapGetters('activity', {
      getActivity: 'getActivity',
    }),
    formattedDisciplineList() {
      const formattedDisciplineList = [];

      this.disciplineList.forEach((discipline) => {
        if (discipline.level === 0) {
          const d = _.clone(discipline);
          formattedDisciplineList.push(d);
        }
      });
      return formattedDisciplineList;
    },
  },
  methods: {
    ...mapActions('activity', {
      createAction: 'CREATE_ACTIVITY',
      editAction: 'EDIT_ACTIVITY',
      initialiseActivity: 'FETCH_DATA_EDIT_ACTIVITY',
    }),
    ...mapActions('discipline', {
      initialiseDisciplineList: 'FETCH_DATA_DISCIPLINE_LIST',
    }),
    inputChanged(event, disciplineId) {
      if (event.srcElement.checked) {
        this.addDisciplineId(disciplineId);
      } else {
        this.removeDisciplineId(disciplineId);
      }
    },
    imageUrl(disciplinename) {
      if (disciplinename === this.disciplineName) {
        const path = `/images/generic/interest/${disciplinename.toLowerCase()}--dark.svg`;
        return require(`@/assets${path}`);
      }
      const path = `/images/generic/interest/${disciplinename.toLowerCase()}--white.svg`;
      return require(`@/assets${path}`);
    },
    formatDuration() {
      const hoursInMs = this.hours * 60 * 60 * 1000;
      const minutesInMs = this.minutes * 60 * 1000;
      const secondsInMs = this.seconds * 1000;

      this.activity.durationInMs = hoursInMs + minutesInMs + secondsInMs;
    },
    formatDateTime() {
      const segments = this.timepicked.split(':');
      this.datepicked.setHours(segments[0].substr(segments[0].length - 2));
      this.datepicked.setMinutes(segments[1].substr(segments[1].length - 2));
      this.activity.tookPlaceOn = this.datepicked.toISOString();
    },
    formatMiliseconds(s) {
      this.hours = new Date(s).getHours() - 1;
      this.minutes = new Date(s).getMinutes();
      this.seconds = new Date(s).getSeconds();
    },
    formatDiscipline() {
      this.activity.disciplineId = this.disciplineList.find((d) => d.name === this.disciplineName).id;
    },
    checkNumber() {
      if (this.minutes.length > 2) {
        this.minutes = this.minutes.substring(0, 2);
      }
      if (this.seconds.length > 2) {
        this.seconds = this.seconds.substring(0, 2);
      }
      if (this.hours.length > 2) {
        this.hours = this.hours.substring(0, 2);
      }
      if (this.activity.distanceInKm.length > 4) {
        this.activity.distanceInKm = this.activity.distanceInKm.substring(0, 4);
      }
    },
    handleFocusOut() {
      if (this.minutes.length === 0) this.minutes = 0;
      if (this.seconds.length === 0 || this.seconds === '0') this.seconds = 0;
      if (this.hours.length === 0) this.hours = 0;
      if (this.activity.distanceInKm.length === 0) this.activity.distanceInKm = 0;
    },
    createNewActivity() {
      this.createAction(this.activity)
        .then(() => {
          this.$router.push({ name: 'profile' });
        })
        .catch((error) => {
          console.log(error);
          this.$toasted.show(...error.response.data);
        });
    },
    updateActivity() {
      this.editAction(this.activity)
        .then(() => {
          this.$router.push({ name: 'profile' });
        })
        .catch((error) => {
          this.$toasted.show(...error.response.data);
        });
    },
    getActivityDetails() {
      this.initialiseDisciplineList().then(() => {
        if (this.$route.params.activityId) {
          this.initialiseActivity(this.$route.params.activityId)
            .then(() => {
              this.editMode = true;
              this.activity = _.cloneDeep(this.getActivity);
              this.datepicked = new Date(this.activity.tookPlaceOn);
              this.timepicked = this.activity.tookPlaceOn;
              this.disciplineName = this.activity.discipline;
              this.formatMiliseconds(this.activity.durationInMs);
              this.isLoaded = true;
            }).catch((error) => {
              if (error.response.status === 404) {
                this.$router.push({ name: '404' });
              } else {
                this.$toasted.show(...error.response.data);
              }
            });
        } else {
          this.isLoaded = true;
        }
      });
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          setTimeout(() => {
            const errorKeys = Object.keys(this.$refs.form.errors);
            const errors = errorKeys.filter((e) => this.$refs.form.errors[e].length > 0);

            this.$refs.form.refs[errors[0]].$el.scrollIntoView();
            window.scrollBy(0, -75);
          }, 100);
        } else if (this.editMode) {
          this.formatDuration();
          this.formatDateTime();
          this.formatDiscipline();
          this.updateActivity();
        } else {
          this.formatDuration();
          this.formatDateTime();
          this.formatDiscipline();
          this.createNewActivity();
        }
      });
    },
    cancel() {
      this.$router.push({ name: 'profile' });
    },
  },
  created() {
    this.getActivityDetails();
  },
};
